import React from "react";
import { Button, Form, Input, Modal } from "antd";

const ReusableFormModal = ({
  title = "",
  isOpen = false,
  onFinish = () => {},
  information = [],
}) => {
  return (
    <Modal title={title} open={isOpen} closable={false} centered footer={null}>
      <Form
        name="basic"
        wrapperCol={{
          span: 24,
        }}
        onFinish={onFinish}
        autoComplete="off"
      >
        <div
          style={{
            marginTop: 25,
            marginBottom: 15,
            display: "flex",
            flexDirection: "column",
            gap: 10,
            justifyContent: "between",
          }}
        >
          {information.map((item) => (
            <Form.Item
              key={item.label}
              name={item.key}
              rules={[
                {
                  required: true,
                  message: `Indtast venligst din ${item.label}!`,
                },
              ]}
            >
              <Input required addonBefore={item.label} value={item.value} />
            </Form.Item>
          ))}
          <Button key="submit" type="primary" htmlType="submit">
            Gem
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default ReusableFormModal;
