import React, { useEffect, useState } from "react";
import ReusableFormModal from "./ReusableFormModal";
import { Input } from "antd";

const FormFilledBy = () => {
  const params = new URLSearchParams(window.location.search);
  const submitToken = params.get("st");
  const savedContact = localStorage.getItem(submitToken);
  const [contact, setContact] = useState({
    name: null,
    phone: null,
    email: null,
  });

  const information = [
    {
      label: "Navn",
      key: "name",
      value: contact?.name,
    },
    {
      label: "Tlf.nr.",
      key: "phone",
      value: contact?.phone,
    },
    {
      label: "E-mail",
      key: "email",
      value: contact?.email,
    },
  ];

  const onFinish = (values) => {
    localStorage.setItem(submitToken, JSON.stringify(values));
    setContact(values);
  };

  useEffect(() => {
    const parsedContact = savedContact && JSON.parse(savedContact);
    setContact(parsedContact);
  }, [savedContact]);

  if (!savedContact) {
    return (
      <ReusableFormModal
        title="Medlemstalsansvarlig - Angiv venligst dine persondata. Vi kontakter dig, hvis der er spørgsmål mht. indberetningen."
        isOpen={!savedContact}
        onFinish={onFinish}
        information={information}
      />
    );
  }

  return (
    <div style={{ display: "flex", justifyContent: "between" }}>
      {information.map((item) => (
        <Input
          key={item.key}
          disabled
          addonBefore={item.label}
          value={item.value}
        />
      ))}
    </div>
  );
};
export default FormFilledBy;
