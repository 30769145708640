import "./App.css";
import ClubDataUI from "./components/ClubDataUI";
import "url-search-params-polyfill";
import React from "react";
import axios from "axios";
import Info from "./components/Info";
import * as _ from "lodash";
import { Button, message } from "antd";
import Welcome from "./components/Welcome";
const hash = require("hash.js");

export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activities: [],
      years: [],
      invalidURL: null,
      submitToken: null,
      departments: [],
      board: null,
      people: null,
    };
  }

  componentDidMount() {
    (async () => {
      const params = new URLSearchParams(window.location.search);
      const submitToken = params.get("st");
      console.log("-> submitToken", submitToken);
      console.log("30");
      if (!submitToken) {
        this.setState({
          invalidURL: true,
        });
        return;
      } else {
        this.setState({
          submitToken: submitToken,
        });
      }

      try {
        const result = await axios.get(
          "https://sdu.ninoxdb.de/share/961cb2lrplrabaq6xjichf7pnnenyve2k4kk"
        );
        this.setState({
          activities: result.data.map((a) => {
            return {
              name: a.activity,
              id: a.id,
            };
          }),
        });
      } catch (e) {
        console.error(e);
        console.log("56");
        this.setState({
          invalidURL: true,
        });
        return;
      }

      try {
        const result = await axios.get(
          "https://sdu.ninoxdb.de/share/08v7xjpn5h86helaegelyj5pr8fdq9ff3305"
        );
        const publicToken = hash.sha256().update(submitToken).digest("hex");
        const found = result.data.find((st) => st.public_token === publicToken);
        console.log("-> found", found);
        if (!found) {
          console.log("70");
          this.setState({
            invalidURL: true,
          });
          return;
        } else {
          this.setState({
            yearlyReportID: found.yearlyReport,
            clubIdInContextOfYearlyReport: found.clubIdInContextOfYearlyReport,
          });
        }
      } catch (e) {
        console.error(e);
        console.log("83");
        this.setState({
          invalidURL: true,
        });
        return;
      }

      axios
        .get(
          //Old API
          "https://sdu.ninoxdb.de/share/vjqo794erj70cu85azyjdcnm132ba1rstyv8"
          //New API
          // "https://sdu.ninoxdb.de/share/k05blkrzowhxywekzfh8gjmxu9m1a72k79qr"
        )
        .then((result) => {
          console.log("result.data", result.data);
          console.log("yearlyReportID", this.state.yearlyReportID);
          const years = result.data.filter(
            (year) => year.yearReportID == this.state.yearlyReportID
          );
          if (years.length === 0) {
            console.log("102");
            this.setState({
              invalidURL: true,
            });
          } else {
            this.setState({
              years: years.map((y) => {
                return {
                  year: y.label,
                  from: y.from,
                  to: y.to,
                  yearId: y.yearId,
                };
              }),
            });
          }
        })
        .catch(console.error);

      try {
        const departments = await axios.get(
          "https://sdu.ninoxdb.de/share/l8y70gecnskmebmi41qzje9t90wi22wbfpb6"
        );
        this.setState({
          departments: departments.data.map((r) => {
            return {
              value: r.id,
              name: r.name,
            };
          }),
        });
      } catch (e) {
        console.error(e);
        console.log("135");
        this.setState({
          invalidURL: true,
        });
        return;
      }

      try {
        const result = await axios.get(
          "https://sdu.ninoxdb.de/share/l8y70gecnskmebmi41qzje9t90wi22wbfpb6"
        );
        this.setState({
          departments: result.data.map((r) => {
            return {
              value: r.id,
              name: r.name,
            };
          }),
        });
      } catch (e) {
        console.log(e);
        console.log("156");
        this.setState({
          invalidURL: true,
        });
        return;
      }

      try {
        const result = await axios.get(
          "https://sdu.ninoxdb.de/share/8up43qasp64qz7cem1u4hzfd7amhelqk0wbe"
        );
        this.setState({
          board: result.data.map((r) => {
            return {
              value: r.id,
              name: r.name,
            };
          }),
        });
      } catch (e) {
        console.error(e);
        return;
      }

      axios
        .get(
          "https://formular.sdu.de/getPeople.php?st=" + this.state.submitToken
        )
        .then((response) => {
          const people = response.data.map((person) => {
            const fields = person.fields;

            return {
              firstName: fields["Vorname"],
              lastName: fields["Nachname"],
              email: fields["E-Mail"],
              street: fields["Straße"],
              plz: fields["Postleitzahl"],
              city: fields["Ort"],
              phoneNumber: fields["Tlf."],
              mobileNumber: fields["Mobil"],
              id: fields["id"],
              departments: person["Abteilungsleiter"] ?? [],
              board: person["Vorstandspositionen"] ?? [],
              trainer_trainer_children:
                person["trainer_trainer_children"] ?? [],
              trainer_trainer_teenager:
                person["trainer_trainer_teenager"] ?? [],
              trainer_trainer_adults: person["trainer_trainer_adults"] ?? [],
              trainer_trainer_seniors: person["trainer_trainer_seniors"] ?? [],
              trainer_supportTrainer_children:
                person["trainer_supportTrainer_children"] ?? [],
              trainer_supportTrainer_teenager:
                person["trainer_supportTrainer_teenager"] ?? [],
              trainer_supportTrainer_adults:
                person["trainer_supportTrainer_adults"] ?? [],
              trainer_supportTrainer_seniors:
                person["trainer_supportTrainer_seniors"] ?? [],
            };
          });
          this.setState({
            people: people,
            originalPeople: _.cloneDeep(people),
          });
        })
        .catch((e) => {
          console.error(e);
          console.log("223");
          this.setState({
            invalidURL: true,
          });
        });
    })();
  }

  updatePeople(people) {
    this.setState({
      people: people,
    });
  }

  showInvalidURL() {
    this.setState({
      invalidURL: true,
    });
  }

  render() {
    if (this.state.invalidURL === true) {
      message.warning("Indsæt et token for at fortsætte");
      return <Welcome />;
    }

    const showUI =
      this.state.activities.length > 0 &&
      this.state.years.length > 0 &&
      this.state.submitToken !== null &&
      this.state.board !== null &&
      this.state.clubIdInContextOfYearlyReport &&
      this.state.people !== null;
    if (showUI) {
      return (
        <div className={"container-fluid"} style={{ maxWidth: "1400px" }}>
          <div>
            <ClubDataUI
              showInvalidURL={this.showInvalidURL.bind(this)}
              updatePeople={this.updatePeople.bind(this)}
              originalPeople={this.state.originalPeople}
              people={this.state.people}
              clubIdInContextOfYearlyReport={
                this.state.clubIdInContextOfYearlyReport
              }
              board={this.state.board}
              departments={this.state.departments}
              submitToken={this.state.submitToken}
              years={this.state.years}
              activities={this.state.activities}
            />
          </div>
        </div>
      );
    } else {
      return <Info>Belastning ...</Info>;
    }
  }
}
