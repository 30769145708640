export default class PersonChangeEvent {
  constructor(personID, eventName) {
    if (!personID) {
      throw new Error("missing person id");
    }
    if (!eventName) {
      throw new Error("missing event name");
    }
    this.personID = personID;
    this.eventType = eventName;
  }
}
