import PersonChangeEvent from "./PersonChangeEvent";
import * as _ from "lodash";

const compareArrays = (
  original,
  edited,
  compare,
  addedHandler,
  removedHandler
) => {
  const events = [];

  // see if deleted
  for (const o of original) {
    const exists = edited.find((e) => {
      return compare(e, o);
    });
    if (!exists) {
      events.push(removedHandler(o));
    }
  }

  // see is created
  for (const e of edited) {
    const exists = original.find((o) => {
      return compare(o, e);
    });
    if (!exists) {
      events.push(addedHandler(e));
    }
  }

  return events;
};

const normalize = (s) => {
  if (s === undefined) {
    return "";
  }
  return String(s).toLowerCase().replace(/\s/g, "");
};

export default function (originalPeople, editedPeople) {
  const peopleCreatedDeletedEvent = compareArrays(
    originalPeople,
    editedPeople,
    (a, b) => a.id === b.id,
    (person) => {
      return new PersonChangeEvent(person.id, "addedPerson");
    },
    (person) => {
      return new PersonChangeEvent(person.id, "deletedPerson");
    }
  );

  const trainerEvents = [];

  for (const editedPerson of editedPeople) {
    const originalPerson = originalPeople.find((p) => p.id === editedPerson.id);
    if (!originalPerson) {
      continue;
    }

    // name changes
    if (
      normalize(editedPerson.firstName) + normalize(editedPerson.lastName) !==
      normalize(originalPerson.firstName) + normalize(originalPerson.lastName)
    ) {
      trainerEvents.push(new PersonChangeEvent(editedPerson.id, "changedName"));
    }

    // check address changes
    const address = (p) =>
      normalize(p.street) + normalize(p.plz) + normalize(p.city);
    if (address(editedPerson) !== address(originalPerson)) {
      trainerEvents.push(
        new PersonChangeEvent(editedPerson.id, "changedAddress")
      );
    }

    // check for changes in email
    if (normalize(editedPerson.email) !== normalize(originalPerson.email)) {
      trainerEvents.push(
        new PersonChangeEvent(editedPerson.id, "changedEmail")
      );
    }

    // check for changes in phone number
    if (
      normalize(editedPerson.phoneNumber) !==
      normalize(originalPerson.phoneNumber)
    ) {
      trainerEvents.push(
        new PersonChangeEvent(editedPerson.id, "changedPhoneNumber")
      );
    }

    // check for changes in mobile number
    if (
      normalize(editedPerson.mobileNumber) !==
      normalize(originalPerson.mobileNumber)
    ) {
      trainerEvents.push(
        new PersonChangeEvent(editedPerson.id, "changedMobilePhoneNumber")
      );
    }

    const changes = [
      {
        person: originalPerson.id,
        original: originalPerson.board ?? [],
        edited: editedPerson.board ?? [],
        eventName: "boardChanges",
      },
      {
        person: originalPerson.id,
        original: originalPerson.departments ?? [],
        edited: editedPerson.departments ?? [],
        eventName: "departmentChange",
      },
      {
        person: originalPerson.id,
        original: originalPerson.trainer_trainer_children ?? [],
        edited: editedPerson.trainer_trainer_children ?? [],
        eventName: "childrenTrainerChange",
      },
      {
        person: originalPerson.id,
        original: originalPerson.trainer_trainer_teenager ?? [],
        edited: editedPerson.trainer_trainer_teenager ?? [],
        eventName: "teenagerTrainerChange",
      },
      {
        person: originalPerson.id,
        original: originalPerson.trainer_trainer_adults ?? [],
        edited: editedPerson.trainer_trainer_adults ?? [],
        eventName: "adultsTrainerChange",
      },
      {
        person: originalPerson.id,
        original: originalPerson.trainer_trainer_seniors ?? [],
        edited: editedPerson.trainer_trainer_seniors ?? [],
        eventName: "seniorTrainerChange",
      },
      {
        person: originalPerson.id,
        original: originalPerson.trainer_supportTrainer_children ?? [],
        edited: editedPerson.trainer_supportTrainer_children ?? [],
        eventName: "childrenSupportTrainerChange",
      },
      {
        person: originalPerson.id,
        original: originalPerson.trainer_supportTrainer_teenager ?? [],
        edited: editedPerson.trainer_supportTrainer_teenager ?? [],
        eventName: "teenagerSupportTrainer",
      },
      {
        person: originalPerson.id,
        original: originalPerson.trainer_supportTrainer_adults ?? [],
        edited: editedPerson.trainer_supportTrainer_adults ?? [],
        eventName: "adultsSupportTrainerChange",
      },
      {
        person: originalPerson.id,
        original: originalPerson.trainer_supportTrainer_seniors ?? [],
        edited: editedPerson.trainer_supportTrainer_seniors ?? [],
        eventName: "seniorSupportTrainerChange",
      },
    ];

    for (const change of changes) {
      const { original, edited, eventName, person } = change;
      if (
        _.difference(original, edited).length !== 0 ||
        _.difference(edited, original).length !== 0
      ) {
        trainerEvents.push(new PersonChangeEvent(person, eventName));
      }
    }
  }

  return [...peopleCreatedDeletedEvent, ...trainerEvents];
}
