export default function (props) {
  return (
    <div className={"container"}>
      <br />
      <br />
      <br />
      <br />
      <div className="alert alert-info" role="alert">
        {props.children}
      </div>
    </div>
  );
}
