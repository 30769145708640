import React from "react";
import AgeLabel from "./AgeLabel";
import NumberInput from "./NumberInput";
import SelectSearch from "react-select-search";
import fuzzySearch from "react-select-search/dist/esm/fuzzySearch";
import { v4 } from "uuid";
import * as _ from "lodash";
import "react-select-search/style.css";
import Panel from "./utils/Panel";
import { TD, TDCol, TDColSpan, TDComplete } from "./utils/TD";
import TextInput from "./TextInput";

export default class Activities extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activities: props.activities.map((a) => {
        return { name: a.name, value: a.id };
      }),
      selectedActivity: null,
      showSelectActivity: false,
    };
  }

  inputStorageKeys(activity, group, year, sex) {
    return `${activity}.${group}.years[${year}].${sex}`;
  }

  handleAddingNewActivity(activity) {
    this.setState({ selectedActivity: activity });
    const activityStorage = this.props.activityStorage;

    const fetchedActivity = activityStorage[activity];
    if (!fetchedActivity) {
      activityStorage[activity] = [
        {
          years: {},
          _id: v4(),
        },
      ];
    } else {
      activityStorage[activity].push({
        years: {},
        _id: v4(),
      });
    }
    this.props.updateActivityStorage(activityStorage);

    localStorage.setItem(`${this.props.submitToken}-activities`, JSON.stringify(activityStorage))
  }

  handleDelete(groups, groupId) {
    _.remove(groups, {
      _id: groupId,
    });
    this.props.updateActivityStorage(this.props.activityStorage);
    localStorage.setItem(`${this.props.submitToken}-activities`, JSON.stringify(this.props.activityStorage))
  }

  showHideSelectActivity() {
    this.setState({
      showSelectActivity: !this.state.showSelectActivity,
    });
  }

  render() {
    const activities = Object.keys(this.props.activityStorage).map((a, i) => {
      const groups = this.props.activityStorage[a];

      const label = this.props.activities.find((fa) => fa.id.toString() === a);
      if (!label) {
        throw new Error(`failed to find label for activity: ${a}`);
      }

      const addNumber = (currentNumber, numberObject) => {
        if (!numberObject || numberObject.value === null) {
          return currentNumber;
        }
        const n = parseInt(numberObject.value, 10);
        if (isNaN(n)) {
          return currentNumber;
        }
        if (n < 0) {
          return currentNumber;
        }
        return currentNumber + n;
      };

      return groups.map((group, groupIndex) => {
        let sum = 0;
        this.props.years.map((y, i) => {
          const m = _.get(
            this.props.activityStorage,
            this.inputStorageKeys(a, groupIndex, y.yearId, "m")
          );
          sum = addNumber(sum, m);

          const f = _.get(
            this.props.activityStorage,
            this.inputStorageKeys(a, groupIndex, y.yearId, "w")
          );
          sum = addNumber(sum, f);
        });

        return (
          <>
            <tr key={group._id + "row"}>
              <td>
                <button
                  className={"btn btn-sm btn-danger"}
                  onClick={() => {
                    this.handleDelete(groups, group._id);
                  }}
                >
                  <span className="fa fa-trash" aria-hidden="true" />
                </button>{" "}
                {label.name} {groupIndex + 1}
              </td>
              {this.props.years.map((y, i) => {
                return (
                  <React.Fragment key={group._id + "main_data" + i}>
                    <TDCol>
                      <NumberInput
                        storageKey={this.inputStorageKeys(
                          a,
                          groupIndex,
                          y.yearId,
                          "m"
                        )}
                        storageLocation={this.props.activityStorage}
                        updateStorage={this.props.updateActivityStorage}
                        submitToken={this.props.submitToken}
                      />
                    </TDCol>
                    <TDCol>
                      <NumberInput
                        storageKey={this.inputStorageKeys(
                          a,
                          groupIndex,
                          y.yearId,
                          "w"
                        )}
                        storageLocation={this.props.activityStorage}
                        updateStorage={this.props.updateActivityStorage}
                        submitToken={this.props.submitToken}
                      />
                    </TDCol>
                  </React.Fragment>
                );
              })}
              <React.Fragment key={group._id + "danishBelow25"}>
                <TDCol>
                  <NumberInput
                    storageKey={`${a}.${groupIndex}.danishBelow25M`}
                    storageLocation={this.props.activityStorage}
                    updateStorage={this.props.updateActivityStorage}
                    submitToken={this.props.submitToken}
                  />
                </TDCol>
                <TDCol>
                  <NumberInput
                    storageKey={`${a}.${groupIndex}.danishBelow25W`}
                    storageLocation={this.props.activityStorage}
                    updateStorage={this.props.updateActivityStorage}
                    submitToken={this.props.submitToken}
                  />
                </TDCol>
              </React.Fragment>
              <React.Fragment key={group._id + "trainer"}>
                <TDCol>
                  <NumberInput
                    storageKey={`${a}.${groupIndex}.trainerM`}
                    storageLocation={this.props.activityStorage}
                    updateStorage={this.props.updateActivityStorage}
                    submitToken={this.props.submitToken}
                  />
                </TDCol>
                <TDCol>
                  <NumberInput
                    storageKey={`${a}.${groupIndex}.trainerW`}
                    storageLocation={this.props.activityStorage}
                    updateStorage={this.props.updateActivityStorage}
                    submitToken={this.props.submitToken}
                  />
                </TDCol>
              </React.Fragment>
              <td>
                <NumberInput
                  storageKey={`${a}.${groupIndex}.countActivityMeetings`}
                  storageLocation={this.props.activityStorage}
                  updateStorage={this.props.updateActivityStorage}
                  submitToken={this.props.submitToken}
                />
              </td>
              <td>{sum}</td>
            </tr>
            <tr>
              <td>Intern gruppebetegnelse</td>
              <TDComplete>
                <TextInput
                  storageKey={`${a}.${groupIndex}.kommentar`}
                  storageLocation={this.props.activityStorage}
                  updateStorage={this.props.updateActivityStorage}
                  submitToken={this.props.submitToken}
                />
              </TDComplete>
            </tr>
          </>
        );
      });
    });

    return (
      <div>
        <div className={"row"}>
          <div className={"col-sm-12"}>
            <Panel
              title={
                "BLOK 2 – Registrering af gruppeaktiviteter. En gruppe udgøres af min. 5 personer der har mødtes min. 10 x i  dette år til aktiviteten"
              }
            >
              <div className={"table-responsive"}>
                <table className={"table table-bordered"}>
                  <thead>
                    <tr>
                      <td>Årgang</td>
                      {this.props.years.map((p, index) => {
                        return <TDColSpan key={index}>{p.year}</TDColSpan>;
                      })}
                      <TDColSpan>Dansk under</TDColSpan>
                      <TDColSpan>Træner / Instruktør / ledere</TDColSpan>
                      <td>Antal møder/</td>
                      <td>Samlet</td>
                    </tr>
                    <tr>
                      <td>Alder</td>
                      {this.props.years.map((p, index) => {
                        return (
                          <TDColSpan key={index}>
                            <AgeLabel from={p.from} to={p.to} />
                          </TDColSpan>
                        );
                      })}
                      <TDColSpan>25 år</TDColSpan>
                      <TDColSpan>-</TDColSpan>
                      <td>træninger</td>
                      <td>antal</td>
                    </tr>
                    <tr>
                      <td>Køn</td>
                      {this.props.years.map((p, index) => {
                        return (
                          <React.Fragment key={index}>
                            <TDCol>M</TDCol>
                            <TDCol>K</TDCol>
                          </React.Fragment>
                        );
                      })}
                      <React.Fragment>
                        <TDCol>M</TDCol>
                        <TDCol>K</TDCol>
                      </React.Fragment>
                      <React.Fragment>
                        <TDCol>M</TDCol>
                        <TDCol>K</TDCol>
                      </React.Fragment>
                      <td style={{ backgroundColor: "#E8E8E8" }}></td>
                      <td> </td>
                    </tr>
                  </thead>
                  <tbody>{activities}</tbody>
                </table>
              </div>
              <div className={"row"}>
                <div
                  onClick={this.showHideSelectActivity.bind(this)}
                  className={"col-sm-4"}
                >
                  {
                    <SelectSearch
                      onChange={this.handleAddingNewActivity.bind(this)}
                      closeOnSelect={false}
                      filterOptions={fuzzySearch}
                      search
                      className={"select-search-custom"}
                      options={this.state.activities}
                      placeholder="Aktivitet"
                      printOptions={
                        this.state.showSelectActivity ? "always" : "never"
                      }
                    />
                  }
                </div>
                <div className={"col-sm-7"}></div>
              </div>
            </Panel>
          </div>
        </div>
      </div>
    );
  }
}
