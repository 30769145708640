import React from "react";
import NumberInput from "./NumberInput";
import AgeLabel from "./AgeLabel";
import Panel from "./utils/Panel";
import _ from "lodash";
import { TDColSpan, TD, TDCol } from "./utils/TD";

class Member extends React.Component {
  createStorageKey(section, yearID, sex) {
    return [section, yearID, sex].join(".");
  }

  render() {
    const addNumber = (currentNumber, numberObject) => {
      if (!numberObject || numberObject.value === null) {
        return currentNumber;
      }
      const n = parseInt(numberObject.value, 10);
      if (isNaN(n)) {
        return currentNumber;
      }
      if (n < 0) {
        return currentNumber;
      }
      return currentNumber + n;
    };

    let totalCountOfMembers = 0;
    this.props.years.map((y) => {
      const skm = _.get(
        this.props.memberCountStorage,
        this.createStorageKey("countOfMembers", y.yearId, "m")
      );
      totalCountOfMembers = addNumber(totalCountOfMembers, skm);

      const skf = _.get(
        this.props.memberCountStorage,
        this.createStorageKey("countOfMembers", y.yearId, "w")
      );
      totalCountOfMembers = addNumber(totalCountOfMembers, skf);
    });

    let below25 = 0;
    this.props.years.map((y) => {
      const m = _.get(
        this.props.memberCountStorage,
        this.createStorageKey("danishBelow25", y.yearId, "m")
      );
      below25 = addNumber(below25, m);

      const f = _.get(
        this.props.memberCountStorage,
        this.createStorageKey("danishBelow25", y.yearId, "w")
      );
      below25 = addNumber(below25, f);
    });

    let passive = 0;
    this.props.years.map((y) => {
      const m = _.get(
        this.props.memberCountStorage,
        this.createStorageKey("passive", y.yearId, "m")
      );
      passive = addNumber(passive, m);

      const f = _.get(
        this.props.memberCountStorage,
        this.createStorageKey("passive", y.yearId, "w")
      );
      passive = addNumber(passive, f);
    });

    return (
      <div className={"row"}>
        <div className={"col-sm-12"}>
          <Panel title={"BLOK 1 – Medlemstal"}>
            <div className={"table-responsive"}>
              <table className={"table table-bordered"}>
                <thead>
                  <tr>
                    <td>Årgang</td>
                    {this.props.years.map((y, index) => {
                      return <TDColSpan key={index}>{y.year}</TDColSpan>;
                    })}
                    <td>Samlet</td>
                  </tr>
                  <tr>
                    <td>Alder</td>
                    {this.props.years.map((y, index) => {
                      return (
                        <TDColSpan key={index}>
                          <AgeLabel from={y.from} to={y.to} />
                        </TDColSpan>
                      );
                    })}
                    <td>antal</td>
                  </tr>
                  <tr>
                    <TD>Køn</TD>
                    {this.props.years.map((p, index) => {
                      return (
                        <React.Fragment key={index}>
                          <TDCol>M</TDCol>
                          <TDCol>K</TDCol>
                        </React.Fragment>
                      );
                    })}
                    <TD> </TD>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Antal medlemmer</td>
                    {this.props.years.map((y) => {
                      return (
                        <React.Fragment key={y.yearId}>
                          <TDCol>
                            <NumberInput
                              storageLocation={this.props.memberCountStorage}
                              storageKey={this.createStorageKey(
                                "countOfMembers",
                                y.yearId,
                                "m"
                              )}
                              updateStorage={
                                this.props.updateMemberCountStorage
                              }
                              submitToken={this.props.submitToken}
                            />
                          </TDCol>
                          <TDCol>
                            <NumberInput
                              storageLocation={this.props.memberCountStorage}
                              storageKey={this.createStorageKey(
                                "countOfMembers",
                                y.yearId,
                                "w"
                              )}
                              updateStorage={
                                this.props.updateMemberCountStorage
                              }
                              submitToken={this.props.submitToken}
                            />
                          </TDCol>
                        </React.Fragment>
                      );
                    })}
                    <td>{totalCountOfMembers}</td>
                  </tr>
                  <tr>
                    <td>U25 dansk mindretal</td>
                    {this.props.years.map((y) => {
                      if (y.from > 25 || y.to > 25) {
                        return (
                          <React.Fragment key={y.yearId}>
                            <td
                              scope="col"
                              style={{ backgroundColor: "#E8E8E8" }}
                            />
                            <td
                              scope="col"
                              style={{ backgroundColor: "#E8E8E8" }}
                            />
                          </React.Fragment>
                        );
                      }
                      return (
                        <React.Fragment key={y.yearId}>
                          <TDCol>
                            <NumberInput
                              storageLocation={this.props.memberCountStorage}
                              storageKey={this.createStorageKey(
                                "danishBelow25",
                                y.yearId,
                                "m"
                              )}
                              updateStorage={
                                this.props.updateMemberCountStorage
                              }
                              submitToken={this.props.submitToken}
                            />
                          </TDCol>
                          <TDCol>
                            <NumberInput
                              storageLocation={this.props.memberCountStorage}
                              storageKey={this.createStorageKey(
                                "danishBelow25",
                                y.yearId,
                                "w"
                              )}
                              updateStorage={
                                this.props.updateMemberCountStorage
                              }
                              submitToken={this.props.submitToken}
                            />
                          </TDCol>
                        </React.Fragment>
                      );
                    })}
                    <td>{below25}</td>
                  </tr>
                  <tr>
                    <td>Antal passive</td>
                    {this.props.years.map((y) => {
                      return (
                        <React.Fragment key={y.yearId}>
                          <TDCol>
                            <NumberInput
                              storageLocation={this.props.memberCountStorage}
                              storageKey={this.createStorageKey(
                                "passive",
                                y.yearId,
                                "m"
                              )}
                              updateStorage={
                                this.props.updateMemberCountStorage
                              }
                              submitToken={this.props.submitToken}
                            />
                          </TDCol>
                          <TDCol>
                            <NumberInput
                              storageLocation={this.props.memberCountStorage}
                              storageKey={this.createStorageKey(
                                "passive",
                                y.yearId,
                                "w"
                              )}
                              updateStorage={
                                this.props.updateMemberCountStorage
                              }
                              submitToken={this.props.submitToken}
                            />
                          </TDCol>
                        </React.Fragment>
                      );
                    })}
                    <td>{passive}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Panel>
        </div>
      </div>
    );
  }
}

export default Member;
