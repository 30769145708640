import React from "react";
import { Select } from "antd";

export function trainerStorageKey(trainerType, group) {
  return "trainer_" + trainerType + "_" + group;
}

export default class ChoseTrainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      label: props.label,
      storageLocation: props.storageLocation,
      person: props.person,
      activities: props.activities,
      show: false,
    };
  }

  handleSelectedActivities(activities) {
    const p = this.props.person;
    p[this.state.storageLocation] = activities;
    this.props.updatePerson(p);
  }

  activities() {
    return this.state.activities.map((a) => {
      return {
        label: a.name,
        value: a.id,
      };
    });
  }

  handleShow(e) {
    this.setState(
      {
        show: !this.state.show,
      },
      () => {
        if (this.state.show === false) {
          e.target.blur();
        }
      }
    );
  }

  handleBlur() {
    this.setState({
      show: false,
    });
  }

  render() {
    return (
      <div className={"row"}>
        <div className={"col-sm-12"}>
          <div className="form-group">
            <label>{this.state.label}</label>
            <Select
              mode="multiple"
              options={this.activities()}
              onChange={this.handleSelectedActivities.bind(this)}
              value={this.state.person[this.state.storageLocation]}
              style={{
                width: "100%",
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}
